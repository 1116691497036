import React from 'react';

const HomeContact = () => {
   return (
      <>
         <section className="contact__area">
            <div className="container-fluid p-0">
               <div className="row g-0">
                  <div className="col-xl-12">
                     <div className="contact__map">
                        {/*<iframe title='contact'*/}
                        {/*        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7986995335873!2d103.85583017365654!3d1.2953575617513151!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19af0fcfc03f%3A0x2d1d7b2854b2f32!2sStraits%20Virtual%20Office%20Singapore!5e0!3m2!1sen!2sae!4v1686522424097!5m2!1sen!2sae"></iframe>*/}


                        <div className="contact__wrapper d-md-flex justify-content-between">
                           {/*<div className="contact__info text-center">*/}
                           {/*   <h3>Get in touch</h3>*/}
                           {/*   /!*<ul>*!/*/}
                           {/*   /!*   <li>*!/*/}
                           {/*   /!*      <h4>Address</h4>*!/*/}

                           {/*   /!*      <p>7 Temasek Boulevard Suntec Tower <br/> One  #12-07 Singapore 038987</p>*!/*/}
                           {/*   /!*   </li>*!/*/}
                           {/*   /!*   <li>*!/*/}
                           {/*   /!*      <h4>call us</h4>*!/*/}
                           {/*   /!*      <p><a href="tel:(404)-888-123-456">(404) 888 123 456</a></p>*!/*/}
                           {/*   /!*      <p><a href="tel:(204)-888-234-674">(204) 888 234 674</a></p>*!/*/}
                           {/*   /!*   </li>*!/*/}
                           {/*   /!*   <li>*!/*/}
                           {/*   /!*      <h4>Email Address</h4>*!/*/}
                           {/*   /!*      <p><a href="mailto:Info@example.com">info@itdatmaster.com</a></p>*!/*/}
                           {/*   /!*   </li>*!/*/}
                           {/*   /!*</ul>*!/*/}
                           {/*</div>*/}

                           <div className="contact__form mb-30 ml-200">
                              <h3>Get in touch</h3>
                              <form action="#">
                                 <input type="text" placeholder="Your Name"/>
                                    <input type="email" placeholder="Your Email"/>
                                       <textarea placeholder="Your Message"></textarea>
                                       <button type="button" className="z-btn " >Send Message</button>
                                    </form>
                                 </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
         </section>
      </>
   );
};

export default HomeContact;