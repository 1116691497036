import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import HomeTwoAchievement from '../HomeTwo/HomeTwoAchievement/HomeTwoAchievement';


const ActivePlan = () => {
   return (
      <>
         <PageHelmet pageTitle="Active Plan Page" />
         <HomeOneHeader/>
         <CommonPageHeader title="Your Active Plan" subtitle="Active Plan" />
         <HomeTwoAchievement/>
         <Footer/>
      </>
   );
};

export default ActivePlan;