import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const HomeAbout = () => {
   return (
      <>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/about/about-1.jpg" alt=""/>
                           <div className="about__shape">
                              <img src="assets/img/about/red-shape.png" alt=""/>
                           </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <span>Who we are</span>
                           <h2>We Unlock Potential</h2>
                        </div>
                        <p>
                           Data Master Information Technology LTD was establish with aim to reduce gap between Work and Location. It provides Remote online and offline jobs to Millions of people globally.
                           We are the company which gives you opportunity to work remotely for every category of person whether they are students, fresher’s, housewives or job-seeker, we provide online / offline remote work to everyone irrespective of their social background, educational qualification, age, and gender. Our remote work plan offers genuine opportunity for people enrol with us to earn extra income from home.
                           Data Master Information Technology LTD is registered with Company House-Govt.UK and have physical office at London United Kingdom. We provide maximum support to our clients through different channels for better quality result with our dedicated team members.

                        </p>

                        <div className="about__list">
                           <ul>
                              <li ><span><i > <FaCheck/> </i>Innovative ideas</span></li>
                              <li ><span><i > <FaCheck/> </i>Professional assistance</span></li>

                           </ul>
                        </div>
                        <Link to="/services" className="z-btn " >What we do<i > <CgArrowLongRight /> </i></Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeAbout;