import React from 'react';
import { Link } from 'react-router-dom';

const HomeThreeSecondSingleService = ({title}) => {
   return (
      <>
         <div className="services__nav-content pt-90 pb-90">
            <div className="row">
               <div className="col-xl-5 col-lg-6">
                  <div className="services__thumb text-lg-right m-img">
                     <img src="assets/img/services/services-1.png" alt="" />
                  </div>
               </div>
               <div className="col-xl-7 col-lg-6">
                  <div className="services__content-3 pl-70 pr-70">
                     {/*<h3>{title}</h3>*/}
                     <p>

                        Data Master Provide solutions to the company which seek data for information service directory, Data Analysis, Consultancy for IT, Network Technology, and handle different IT Projects.
                        Data Master collect data from one source and furnish it for client as per client requirements through different ways and channels.
                        Data Master also provide Data Base designs for the clients.
                        An expert team has been set to support our client to assure 99.99% of satisfaction.

                     </p>


                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeThreeSecondSingleService;