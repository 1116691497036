import React, {useRef} from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import axios from 'axios'
import {Link} from "react-router-dom";
import {toast} from "react-toastify";




const Enroll = () => {

    let name, email, phone, country, state = useRef();
    const SaveData=()=>{

        let Name = name.value;
        let Email = email.value;
        let Phone = phone.value;
        let Country = country.value;
        let State = state.value;


       // let URL='http://127.0.0.1:8000/api/enroll/register';
       let URL='https://super.itdatamaster.com/api/enroll/register';

        let jsonData={
            name:Name,
            email:Email,
            phone:Phone,
            country:Country,
            state:State
        }
        axios.post(URL,jsonData).then(function (response) {
                   // console.log(JSON.stringify(response.data));
                    if (response.data==1){
                        toast("Register success!");
                    }else{
                        toast("Something went wrong!");
                    }

                })
                .catch(function (error) {
                  //  console.log(error);
                    toast("Something went wrong!");
                });


    }

    const styles = {
        try:{
            height: '50px',
            lineHeight: '46px',
            color: '1f1841',
            width: '100%',
            background: '#f5f5f5',
            border: '2px solid transparent',
            borderRadius: '10px',
            marginBottom: '15px',
            padding: '0 15px'
        }

    };

   return (
      <>

         <PageHelmet pageTitle="Signup Page" />

         <HomeOneHeader/>
         <CommonPageHeader title="Sign up" subtitle="Sign up" />
          <section className="services__area-3 pt-5 pb-160">
              <div className="container">

                  <div className="row">

                      <div className="col-xl-12">
                          <div className="services__tab grey-bg-18">
                              <div className="tab-content" id="services-content">
                                  <div className="tab-pane fade show active" id="share" role="tabpanel" aria-labelledby="share-tab">
                                      <div className="services__nav-content pt-90 pb-90">
                                          <div className="row">
                                              <div className="col-xl-5 col-lg-6">
                                                  <div className="services__thumb text-lg-right m-img">
                                                      <img src="assets/img/services/services-1.png" alt="" />
                                                  </div>
                                              </div>
                                              <div className="col-xl-7 col-lg-6 col-md-6">
                                                  <div className="services__content-3">
                                                      {/*<h3>{title}</h3>*/}
                                                      <div  className="contact__form">
                                                          {/*<form onSubmit={onSubmit} method='POST'>*/}
                                                              <input  type="text"  ref = {input => name = input}  placeholder="Your Name"  />
                                                              <input  type="email" ref = {input => email = input}  placeholder="Your Email" />
                                                              <input  type="text"  ref = {input => phone = input}  placeholder="Contact Number" />
                                                              <input  type="text"   ref = {input => country = input}  placeholder="Your Country" />
                                                              <input  type="text"  ref = {input => state = input}  placeholder="Your State" />

                                                              {/*<textarea required placeholder="Your Message"></textarea>*/}

                                                              <button  onClick={SaveData} type="button" className="btn btn-sm btn-outline-info">Join Now</button>

                                                          <hr/>
                                                          <p style={{color:'red'}}>
                                                              Note: If you are already registered Member, kindly proceed to the payment by clicking on <Link to="/payment"> <span className='btn btn-sm btn-success'>Pay  Now</span> </Link>

                                                          </p>

                                                          {/*</form>*/}
                                                      </div>


                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
         <Footer/>
      </>
   );
};

export default Enroll;