import React from 'react';
import SinglePrice from '../../../components/SinglePrice/SinglePrice';

const HomePricing = () => {

//   let url='http://localhost:3000/'
   let url='https://itdatamaster.com/'
   let srcPdf1=url+'Bronze_Package.pdf';
   let srcPdf2=url+'Silver_Package.pdf';
   let srcPdf3=url+'Gold_Package.pdf';
   return (
      <>
         <section className="price__area pt-115">
            <div className="container">
               <div className="row">

                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 mb-40 text-center">
                        <span>Data Plans</span>
                        <h2>Flexible Pricing Packages</h2>

                     </div>
                     <div className="price__tab d-flex justify-content-center mb-50">
                        {/*<div className="price__offer">*/}
                        {/*   <span>Save 20%</span>*/}
                        {/*   <img src="assets/img/icon/price/line.png" alt=""/>*/}
                        {/*</div>*/}
                        <ul className="nav nav-tabs justify-content-center" id="price-tab" role="tablist">

                            <li className="nav-item">
                              <button className="nav-link active" id="yearly-tab" data-bs-toggle="tab" data-bs-target="#yearly" role="tab" aria-controls="yearly" aria-selected="true">Monthly</button>
                           </li>

                           {/*<li className="nav-item">*/}
                           {/*   <button className="nav-link" id="monthly-tab" data-bs-toggle="tab"*/}
                           {/*      data-bs-target="#monthly" role="tab" aria-controls="monthly"*/}
                           {/*      aria-selected="false">Yearly</button>*/}
                           {/*</li>*/}

                        </ul>
                     </div>
                  </div>
               </div>

               <div className="price__tab-content">
                  <div className="tab-content" id="price-tab-content">
                     <div className="tab-pane fade" id="monthly" role="tabpanel" aria-labelledby="monthly-tab">
                        <div className="row">

                           <SinglePrice title="Free" price="0" />
                           <SinglePrice title="Premium" price="47" active="active" />
                           <SinglePrice title="Enterprise" price="74" />

                        </div>
                     </div>

                     <div className="tab-pane fade show active" id="yearly" role="tabpanel" aria-labelledby="yearly-tab">
                        <div className="row">

                           <SinglePrice membership='Bronze' title="Online Data Entry Work" price="118" validity='1 Month' perMonth='1500' perDay='50' monthlyEarning='315' nme='315£+0.00£=315£' refund='Not Refundable' pdf={srcPdf1} />
                           <SinglePrice membership='Silver' title="Online Data Entry Work" price="157" validity='3 Months' perMonth='1800' perDay='60' monthlyEarning='551' nme='551£+157£=708£' refund='Refundable' pdf={srcPdf2} active="active" />
                           <SinglePrice membership='Gold' title="Online Data Entry Work" price="275" validity='5 Months' perMonth='2400' perDay='80' monthlyEarning='786' nme='786£+157£=943£' refund='Refundable' pdf={srcPdf3}  />

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

      </>
   );
};

export default HomePricing;