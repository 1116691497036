import React from 'react';
import { Link } from 'react-router-dom';

const SinglePrice = ({title,price,validity,perMonth,perDay,monthlyEarning,nme,refund,membership,active,pdf}) => {

    // const viewPdf=()=>{
    //     return  <div style={{overflow:'scroll',height:600}}>
    //         <a href=""></a>
    //     </div>
    //
    //     // <Viewer fileUrl="./../../Complete_Details_of_Bronze_Package.pdf" />;
    //
    // }

    return (
      <>
         <div className="col-xl-4 col-lg-4 col-md-6">
            <div className={`price__item ${active && active} p-relative transition-3 text-center fix mb-30`}>
               <div className="price__shape transition-3 p-absolute">
                  <img src="assets/img/icon/price/triangle.png" alt=""/>
               </div>
               <div className="price__inner p-relative">
                 <h4 style={{color:'yellowgreen'}}>{membership}</h4>
                   <hr style={{color:'black'}}/>
                  <p>{title}</p>
                  <div className="price__tag mb-45">
                     <h1>{`${price}£`}</h1>
                     <span>{`${validity}`}</span>
                  </div>
                  <div className="price__features text-start mb-55">
                     <ul>
                        <li><span>Payout: Monthly(Bank/Paypal)</span></li>
                        <li><span>Assignment Per Month : {`${perMonth}`} Docs</span></li>
                        <li><span>Assignment Per Day : {`${perDay}`} Docs</span></li>
                        <li><span>Monthly Earning : {`${monthlyEarning}`} £</span></li>
                        <li><span>Net Monthly Earning : {`${nme}`} </span></li>
                        <li><span className=''>Demo</span></li>
                        <li><span> {`${refund}`}</span></li>
                     </ul>
                  </div>
                   <a href={pdf} target='_blank'><span style={{color:'yellowgreen'}}> Complete Details</span></a>
                   <hr/>
                  <Link to="/enroll" className="price-btn">Start Now</Link>
               </div>
            </div>
         </div>
      </>
   );
};

export default SinglePrice;