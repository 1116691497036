import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Home from './pages/Home/Home';
import Services from "./pages/Services/Services";
import Terms from "./pages/Terms/Terms";
import ActivePlan from "./pages/ActivePlan/ActivePlan";
import Enroll from "./pages/Enroll/Enroll";
import Payment from "./pages/Enroll/Payment";
import {ToastContainer} from "react-toastify";


function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollTop/>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/home" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/services" element={<Services/>} />
            <Route path="/contact" element={<Contact/>} />
            <Route path="/terms" element={<Terms/>} />
            <Route path="/active-plan" element={<ActivePlan/>} />
            <Route path="/enroll" element={<Enroll/>} />
            <Route path="/payment" element={<Payment/>} />

        </Routes>
      </BrowserRouter>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
        {/* Same as */}
        <ToastContainer />
    </>
  );
}

export default App;
