import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import HomeTwoFaq from '../HomeTwo/HomeTwoFaq/HomeTwoFaq';

const About = () => {
   return (
      <>
         <PageHelmet pageTitle="Terms Page" />
         <HomeOneHeader/>
         <CommonPageHeader title="Terms & Policies" subtitle="Terms" />
         <HomeTwoFaq/>
         <Footer/>
      </>
   );
};

export default About;