import React, {useRef} from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import axios from 'axios'
import {toast} from "react-toastify";


const Enroll = () => {
    let email,data_plan, card_no,exp_month,exp_year,cvc = useRef();
    const SaveData=()=>{

        let Email = email.value;
        let DataPlan = data_plan.value;
        let CardNo = card_no.value;
        let ExpMonth = exp_month.value;
        let ExpYear = exp_year.value;
        let Cvc = cvc.value;

      // let URL='http://127.0.0.1:8000/api/enroll/stripe/payment';
        let URL='https://super.itdatamaster.com/api/enroll/stripe/payment';
        let jsonData={

            email:Email,
            data_plan:DataPlan,
            card_no:CardNo,
            exp_month:ExpMonth,
            exp_year:ExpYear,
            cvc:Cvc

        }
        axios.post(URL,jsonData).then(function (response) {
          //  console.log(JSON.stringify(response.data));
            if (response.status==200){
                toast(response.data.message);
            }else{
                toast(response.data.stripe_error);
            }
        })
            .catch(function (error) {
                //console.log(error);
                toast("Something went wrong!");
            });


    }



   return (
      <>

         <PageHelmet pageTitle="Enroll Page" />

         <HomeOneHeader/>
         <CommonPageHeader title="Enroll" subtitle="Enroll" />
          <section className="services__area-3 pt-5 pb-160">
              <div className="container">

                  <div className="row">
                      <div className="col-xl-12">
                          <div className="services__tab grey-bg-18">
                              <div className="tab-content" id="services-content">
                                  <div className="tab-pane fade show active" id="share" role="tabpanel" aria-labelledby="share-tab">
                                      <div className="services__nav-content pt-90 pb-90">
                                          <div className="row">
                                              <div className="col-xl-5 col-lg-6">
                                                  <div className="services__thumb text-lg-right m-img">
                                                      <img src="assets/img/services/services-1.png" alt="" />
                                                  </div>
                                              </div>
                                              <div className="col-xl-7 col-lg-6">
                                                  <div className="paym">
                                                      <div className="offset-md-2 col-sm-12 col-md-8">
                                                          <div className="card">
                                                              <div className="card-header">
                                                                  <strong className="">Credit Card</strong>
                                                                  <small className=""> enter your card
                                                                      details</small>
                                                              </div>


                                                              {/*@if(\Illuminate\Support\Facades\Session::has('stripe_error'))*/}
                                                              <div className="text-white text-danger text-center">
                                                                  <strong>
                                                                      {/*{{\Illuminate\Support\Facades\Session::get('stripe_error')}}*/}
                                                                  </strong>

                                                              </div>

                                                              {/*@endif*/}
                                                              {/*//{{url('/stripe/post')}}*/}

                                                                  <div className="card-body">

                                                                      <div className="row">
                                                                          <div className="col-sm-12">
                                                                              <div className="form-group"
                                                                                   id="card-container">
                                                                                  <label className="">Your Email
                                                                                     </label>

                                                                                  <div className="input-group">
                                                                                      <input
                                                                                             ref = {input => email = input}
                                                                                             className="form-control"
                                                                                             id="card"
                                                                                             type="email"
                                                                                             placeholder="mail@gmail.com or xyz001"/>
                                                                                  </div>
<br/>

                                                                                  <label className="">Select Plan</label>

                                                                                  <div className="input-group">

                                                                                      <select ref = {input => data_plan = input} id="card" className="form-control">
                                                                                          <option value="bronze">Bronze</option>
                                                                                          <option value="silver">Silver</option>
                                                                                          <option value="gold">Gold</option>
                                                                                      </select>
                                                                                  </div>
                                                                                  <br/>


                                                                                  <label className="">Credit
                                                                                      Card Number</label>

                                                                                  <div className="input-group">
                                                                                      <input name="card_no"
                                                                                             ref = {input => card_no = input}

                                                                                             className="form-control"
                                                                                             id="card" maxLength="16"
                                                                                             type="text"
                                                                                             placeholder="0000 0000 0000 0000"/>
                                                                                      <div id="logo"></div>

                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                      <br/>
                                                                      <div className="row">
                                                                          <div className="form-group col-sm-4">
                                                                              <label
                                                                                  className="">Month</label>
                                                                              <div className="input-group">
                                                                                  <input name="exp_month"
                                                                                         ref = {input => exp_month = input}

                                                                                         type="text"
                                                                                         className="form-control"
                                                                                         id="ccmonth" data-mask="00"
                                                                                         placeholder="MM"/>

                                                                              </div>

                                                                          </div>
                                                                          <div className="form-group col-sm-4">
                                                                              <label className="">Year</label>
                                                                              <div className="input-group">
                                                                                  <input name="exp_year"
                                                                                         ref = {input => exp_year = input}
                                                                                         type="text"
                                                                                         className="form-control"
                                                                                         id="ccyear" data-mask="00"
                                                                                         placeholder="YY"/>
                                                                              </div>

                                                                          </div>
                                                                          <div className="col-sm-4">
                                                                              <div className="form-group">
                                                                                  <label
                                                                                      className="">CVV/CVC</label>
                                                                                  <input name="cvc"
                                                                                         ref = {input => cvc = input}
                                                                                         className="form-control"
                                                                                         id="cvv" type="text"
                                                                                         data-mask="000"
                                                                                         placeholder="123"/>
                                                                              </div>


                                                                          </div>
                                                                      </div>
                                                                  </div>

                                                                  <div className="card-footer">
                                                                      <button className="btn btn-sm btn-danger"
                                                                              type="reset">
                                                                          <i className="mdi mdi-lock-reset"></i> Reset
                                                                      </button>
                                                                      <input type="submit" onClick={SaveData}
                                                                             className="btn btn-sm btn-success float-right"
                                                                             value="Continue"/>
                                                                  </div>

                                                          </div>
                                                      </div>
                                                  </div>



                                              </div>
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </div>






              </div>
          </section>


         <Footer/>
      </>
   );
};

export default Enroll;