import React from 'react';


const HomeServices = () => {
   return (
      <>
         <section className="services__area pt-115">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center wow fadeInUp" data-wow-delay=".2s">
                        <span>Our Services</span>
                        <h2>Provide awesome customer service.</h2>
                     </div>
                  </div>
               </div>

            </div>
         </section>
      </>
   );
};

export default HomeServices;